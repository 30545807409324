@media (max-width: 1365px) {
  h4 {
    font-size: 1.5rem !important;
  }

  h5 {
    font-size: 1.25rem !important;
  }

  h6 {
    font-size: 1rem !important;
  }
}

@media (max-width: 1279px) {
  h5 {
    font-size: 1rem !important;
  }
}

@media (max-width: 959px) {
  h5 {
    font-size: 1.5rem !important;
  }
}

@media (max-width: 599px) {
  h4 {
    font-size: 1.25rem !important;
  }

  h5 {
    font-size: 1.25rem !important;
  }
}
