.truncatedescription {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  
    /* Limit the text block to three lines */
    -webkit-line-clamp: 3;
  }

  .truncate {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  
    /* Limit the text block to three lines */
    -webkit-line-clamp: 1;
  }