.imageSize{
    height: fit-content;
}

/* .crop {
    width: 200px;
    height: 500px;
    overflow: hidden;
}

.crop img {
    height: 700px;
    margin: -50px 0 0 0px;
} */

@media (min-width:620px){
.centered-and-cropped {
    
     object-fit: cover;
     object-position: center 30%;
     height: 500px;
    }
    

    .centered-and-fill{
        object-fit: fill;
        /* height: 380px; */

    }
}
    .centered-and-play{
    /* width: 100%; */
    width: 10%;
    /* transform: translate(490px, -300px); */
    position: absolute;
    left: 45%;
    top: 35%;
    }