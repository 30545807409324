.searchThumbnail {
  position: relative;
  img {
    width: 100%;
  }
}
.video-title {
  h6 {
    padding-left: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    font-size: 1rem;
    font-weight: 400;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
}

.over-thumbnail {
  position: absolute;
  bottom: 5%;
  background: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 0 0.5rem;
  &.view {
    right: 5%;
    display: flex;
    align-items: center;
  }
}
.description {
  padding-top: 0.75rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  font-size: 0.75rem;
  font-weight: 400;
}
.searchHdr {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.searchInfo1 { margin-bottom: 1.25rem; }
.searchInfo1 .cardContent { padding: 0 5px; text-align: center; }