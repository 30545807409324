.MuiPaper-root.seeMoreBtn {
  background-color: transparent;
}

.MuiExpansionPanelSummary-content {
  justify-content: flex-end;
}

.details {
  display: initial;
}
