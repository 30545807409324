.subscriptions-container {
    // color: white;
    padding: 1rem;

    .header {
        margin-bottom: 2rem;
    }

    h4, h3 {
        // color: white;
    }

    .description {
        padding: 0;
        padding-left: 1rem;
        p {
            margin-bottom: 0;
            font-size: 0.75rem;
        }
        .package-descp {
            padding: 1rem;
            background: rgba(0, 0, 0, 0.05);
            .purchaseDropdown {
                font-size: 1rem;
                padding: 0.1rem 0.25rem;
            }
            h6 {
                color: var(--momo-orange-color);
            }
            span {
                // color: rgba(255, 255, 255, 0.6);
            }
            .price {
                margin: 1rem 0;
                font-size: 1rem;
                font-weight: 500;
            }
            button {
                background-color: #e93e22;
                span {
                    color: white;
                }
            }
        }
    }

    .one-subscription {
        margin-bottom: 2rem;
        padding: 10px;
        background: white;

        h4 {
            margin-bottom: 1.5rem;
            color: var(--momo-orange-color);
        }
    }
}