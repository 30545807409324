.MuiTypography-displayBlock {
  display: inline;
  margin-right: 1rem;
  // color: #c3ca02;
  // color: rgba(195, 202, 2, 0.65);
}

.likesDislikesGrid {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.likesDislikesContainer {
  display: flex;
  justify-content: flex-end;

  div {
    display: flex;
  }

  div:first-child,
  div:nth-child(2) {
    margin-right: 1.5rem;
  }

  i {
    margin-right: 7px;
    cursor: pointer;
  }

  button i {
    margin-right: 0;
  }
}

.videoInfo {
  position: relative;
  // border-right: 1px solid rgba(255, 255, 255, 0.5);

  div:first-child {
    overflow: inherit;
    white-space: normal;
  }

  h6 {
    word-break: break-all;
    font-size: 1.25rem;
    font-weight: 400;
  }
}

.channelName {
  height: 100%;
  h6 {
    overflow: inherit;
    white-space: normal;
    word-break: break-all;
    font-size: 1.25rem;
    font-weight: 400;
  }
  // div {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  // }

  button {
    width: 140px;
    border-radius: 0;
    padding: 3px 16px;
  }
}
.comments {
  h6 {
    word-break: break-all;
    font-size: 1.25rem;
    font-weight: 400;
  }
}
.channelDescription {
  padding-top: 1rem;
  margin: 0;
  width: 100%;
}
.videoList, .comments {
  padding: 0 0.75rem;
}

.channelTitle {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  @media (max-width: 599px) {}
}

.modal {
  position: absolute;
  width: 100%;
  height: 200%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 5;

  .loginModal {
    width: 35%;
    text-align: center;
    position: fixed;
    left: 35%;
    top: 25%;
    padding: 3rem 4rem;
    background-color: #303030;
    @media (max-width: 599px) {
        width: 100%;
        left: 0;
        top: 20%;
        padding: 3rem 3rem;
    }
  

    span {
      margin-right: 0;
    }
  }
}

.modal.hide {
  display: none;
}

.modal.show {
  display: show;
  // flex-direction: column;
  // justify-content: center;
}

@media (max-width: 1279px) {
  .videoInfo {
    border-right: none;
  }
}

.commentSection {
  display: flex;
  justify-content: flex-start;
  .userImage {
    flex: 1;
    max-width: 50px;
    img {
      width: 100%;
      border-radius: 50%;
    }
  }
  .comments {
    // display: flex;
    // flex: 5;
    width: calc(100% - 50px);
    flex-direction: column;
    padding-left: 1rem;
    margin-bottom: 0.5rem;

    .commenter {
      margin: 0 0 0.25rem 0 !important;
      // font-weight: bold;
      font-size: 1.125rem;
      // width: 100%
    }
    .commentBody {
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
      // width: 100%
    }
    .subCommentSection {
      // flex: 5;
      // display: flex;
      // margin-top: 1rem;
      // padding-left: 1rem;
      .MuiTypography-body1 {
        flex-grow: 1;
      }
      .MuiExpansionPanelSummary-content {
        margin: 4px 0;
        .MuiTypography-body1 {
          font-size: 0.75rem;
          line-height: 1;
          opacity: 0.5;
        }
      }
      .replyComment {
        background: transparent;
        box-shadow: 0 0 0 0;
        flex-grow: 0;
      }
      .MuiExpansionPanelSummary-root {
        padding: 0;
        justify-content: flex-start;
        flex-grow: 0;
        min-height: 30px;
        text-transform: uppercase;
      }
      .MuiExpansionPanelDetails-root {
        padding: 8px 0;
      }
      .commentReplies {
        box-shadow: 0 0 0 0;
        background: transparent;
      }
      .subComments {
        display: flex;
        // flex-direction: row;
        justify-content: flex-start;
        .subUserImage {
          max-width: 40px;
          img {
            width: 100%;
            border-radius: 50%;
          }
        }
        .MuiExpansionPanelSummary-root {
          padding: 0;
          justify-content: flex-start;
          flex-grow: 0;
          text-transform: uppercase;
        }
        .MuiExpansionPanelDetails-root {
          padding: 0;
        }
        .commentReplies {
          box-shadow: 0 0 0 0;
          background: transparent;
        }
        .subComments {
          display: flex;
          // flex-direction: row;
          justify-content: flex-start;
          .subUserImage {
            max-width: 40px;
            img {
              width: 100%;
              border-radius: 50%;
            }
          }
          .comments {
            flex-direction: column;
          }
        }
      }
    }
  }
}

.commentField {
  width: 100%;
  margin-bottom: 1rem;
}

.main-container {
  margin: 0 auto;
  max-width: 1500px;
}

@media (max-width: 1366px) {
  .main-container {
    margin: 0 auto;
    max-width: 1024px;
  }
}

@media (max-width: 1279px) {
  .main-container {
    margin: 0 auto;
    max-width: 960px;
  }
}

@media (max-width: 959px) {
  .main-container {
    margin: 0 auto;
    max-width: 820px;
  }
}

@media (max-width: 599px) {
  .main-container {
    /* margin: 0 auto; */
    margin: 0 auto; 
    max-width: 420px!important;
    /* max-width: 100vw; */
  }
}

@media (max-width: 960px) {
  .main-container {
    margin-bottom: 40px;
  }
}

@media (max-width: 960px) {
  .main-container {
    margin-bottom: 50px;
  }
}
