.gridContainerContact {
    padding: 5rem;

    .MuiTextField-root { 
        width: 100%;
        margin-bottom: 1rem; 
    }

    .circle {
        height: 100%;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: var(--momo-orange-color);
        color: white;
        padding: 0 2rem;
        border-top-left-radius: 60%;

        h6, p {
            color: white;
        }
    }
}

@media (max-width: 599px) {
    .gridContainerContact .circle {
        text-align: left;
        padding: 0.5rem 2rem;
        border-top-left-radius: 0;
    }

    .gridContainerContact {
        padding: 2rem 1rem;
    }
}