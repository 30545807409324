.MuiButton-root {
  border-radius: 0;
}

.userCoverPhoto {
  img {
    width: 100%;
  }
}

.userInfoWrapper {
  margin: 2rem 1.5rem;
  width: 100%;

  input {
    width: 100%;
  }

  .userLink {
    padding: 3rem;
  }

  .pp {
    padding: 1rem;
    position: relative;
    background-color: rgba(255,255,255,0.1);

    .editIcon {
      position: absolute;
      top: 6%;
      right: 0;
    }
  }

  .userLink {
    position: relative;

    .buttons {
      position: absolute;
      width: 88%;
      bottom: 7%;
      text-align: right;

      .MuiButton-root {
        border-radius: 0;
        padding: 6px 45px;
      }

      button:first-child {
        margin-right: 2rem;
      }
    }

    h6 {
      display: block;
    }

    a {
      color: rgba(255, 255, 255, 0.5);
      text-decoration: none;

      &:hover {
        color: inherit;
      }
    }
  }

  .nameProf {
    padding: 2rem;
    display: flex;

    div:first-child {
      flex: 4;
    }

    div:last-child {
      flex: 1;
    }

    .MuiFormControl-marginNormal {
      // margin: 0;
      width: 100%;
    }
  }

  .editIcon {
    width: 100%;
    text-align: right;
  }

  .userInfo {
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.1);

    .nameProf {
      padding: 1rem;
      box-shadow: 0 0 1px 0px;
      background-color: white;
    }

    .nameProf:first-child {
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    }

    h6,
    .userContact {
      color: rgba(255, 255, 255, 0.6);
    }
  }
}

.topup-container {
  h5 {
    text-align: center;
    color: white;
  }

  .topup-header {
    margin-bottom: 1rem;
  }
}

.payoption-wrapper {
  display: flex;
  justify-content: space-evenly;

  .payoption {
    width: 40%;
    text-align: center;
    cursor: pointer;

    &:hover {
      img {
        box-shadow: 0 0 3px 1px rgba(255, 255, 255, 0.5);
      }
      p {
        text-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    } 

    p {
      background-color: white;
      padding: 0.5rem 0;
      border-radius: 10px;
      box-shadow: 0 0 1px 0;
    }

    img {
      border-radius: 10px;
      box-shadow: 0 0 2px 0px;
    }
  }
}

.userImageDiv {
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;

  img {
    width: 100%;
  }
}

.navPP {
  width: 45px;
  height: 45px;
  display: flex;
  padding: 5px;
  border-radius: 50%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
.MuiSelect-select, .MuiSelect-icon { color: rgba(0, 0, 0, 0.6); }

@media (max-width: 599px) {
  .userInfoWrapper {
    margin: 0;
  }
  .userImageDiv {
    width: 55%;
    margin: auto;
  }
}