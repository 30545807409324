.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  /* color: #61dafb; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-container {
  margin: 0 auto;
  max-width: 1500px;
}

@media (max-width: 1366px) {
  .main-container {
    margin: 0 auto;
    max-width: 1024px;
  }
}

@media (max-width: 1279px) {
  .main-container {
    margin: 0 auto;
    max-width: 960px;
  }
}

@media (max-width: 959px) {
  .main-container {
    margin: 0 auto;
    max-width: 820px;
  }
}

@media (max-width: 599px) {
  .main-container {
    /* margin: 0 auto; */
    margin: 0 auto;
    max-width: 420px !important;
    /* max-width: 100vw; */
  }
}

@media (max-width: 960px) {
  .main-container {
    margin-bottom: 40px;
  }
}

.link {
  cursor: pointer;
}
@media (max-width: 1100px) {
  .imageWidth {
    height: 100% !important;
    width: 300px !important;
    background-color: white;
  }
  .imageDiv1 .playlistInThumbnail {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    width: 35% !important;
    height: 100% !important;
    background-color: rgba(0, 0, 0, 0.85) !important;
    color: white !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }

  .imageDiv1 .playlistInThumbnail img {
    transform: translate(25px, 0px) !important;
  }
  .imageDiv .playlistInThumbnail img {
    transform: translate(47px, 0px) !important;
    width: 35%;
  }
  .imageDiv1 {
    overflow: hidden !important;
    position: relative !important;
  }
  .imageDiv {
    height: 161.55px !important;
    overflow: hidden !important;
    position: relative !important;
  }
  .imageDiv .playlistInThumbnail {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    width: 35% !important;
    height: 100% !important;
    background-color: rgba(0, 0, 0, 0.85) !important;
    color: white !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
  .imageDiv .playlistInThumbnail h6 {
    color: white !important;
    text-align: center !important;
  }
}
