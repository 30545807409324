body {
  /* background-color: #111; */
  /* color: rgba(255, 255, 255, 0.8); */
}

.channelBanner {
  height: 20rem;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.channelBanner img {
  width: 100%;
}

.channelHeader img {
  width: 100%;
}

.channelImageDiv {
  width: 195.66px;
  height: 195.66px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
}

.channelTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.channelInfoWrapper {
  background-color: #303030;
  margin-top: 0.72%;
}

.subscribeBtn {
  text-align: right;
  align-self: center;
}

.subscribeBtn button {
  background-color: #832b2a;
  border-radius: 0;
}

@media (max-width: 1279px) {
  .channelBanner {
    height: 15rem;
  }
}

@media (max-width: 959px) {
  .channelBanner {
    height: 10rem;
  }
}

@media (max-width: 599px) {
  .channelBanner {
    height: 7rem;
  }
}
