.audioCenter{
    text-align: center;
    margin-bottom: 10px;
}

.audioFixed{
    position: fixed;
    box-shadow: 3px 3px 5px 6px #ccc; 
    margin-right: 1rem;
    
}

.audioFixed img{
    height: 300px ;
}
/* @media screen and (min-width: 480px) {
    .fmChange{
        position: fixed;
    }
  } */
  @media screen and (max-width: 800px) {
    .fmChange{
        position: fixed;
        top: 78%;
        /* left: 9%; */
    }
    .fmChange img{
        display: none;
    }
    .mBottom{
        margin-bottom: 160px;
    }
    .audioFixed{
        /* box-shadow: 3px 3px 5px 6px #ccc;  */
        background-color: black;
        width: 100%;
        margin-bottom: 20px;
    }
    .audioCenter{
        text-align: center;
    }

  }