.oneVideoWrapper {
  display: flex;

  div:first-child {
    width: 20%;
  }
  div:last-child {
    width: 80%;
  }
}

.oneVideoListingWrapper {
  h6 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    font-size: 1rem;
    font-weight: 400;
    margin-top: -16px;
  }
}
.views {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  width: auto !important;
  flex-direction: row !important;
  padding: 3px !important;
  background: rgba(0, 0, 0, 0.8);
}
.views i {
  font-size: 1rem;
  margin-right: 3px;
}
.views span {
  margin: 0;
}
