.MuiContainer-maxWidthLg {
  max-width: 1280px;
}

.mainlogo {
  height: 60px;
}

.navLogin {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  color: white;
  text-decoration: none;
}
.navLogin span {
  margin-right: 0;
  font-weight: 600;
}
.navLogin:hover {
  background-color: rgba(255, 255, 255, 0.1);
  text-decoration: none;
}
.navbarLogoDiv {
  width: 10%;
}
.MuiListItemIcon-root {
  min-width: 0;
  width: 35px;
  margin-right: 1.5rem;
}

.MuiDrawer-paper {
  overflow-y: auto;
}
.MuiDrawer-paper::-webkit-scrollbar {
   width: 6px;
}

.MuiDrawer-paper::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
   /* border-radius: 10px; */
   background-color: white;
}

.MuiDrawer-paper::-webkit-scrollbar-thumb {
   /* border-radius: 10px; */
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
   background-color: rgba(0,0,0,0.1);
}

.MuiPaper-root { background-color: white; }
.MuiIconButton-root { color: rgba(0,0,0,0.65); } 

.sideMenuHr { background-color: rgba(0,0,0,0.2); }
.MuiTypography-root, .MuiInputBase-root { color: black; }

.copyright {
  color: black;
  text-align: center;
  margin: 0;
  padding: 1rem 0;
  background-color: rgba(0,0,0,0.2);
  font-size: 12px!important;
}

@media (min-width: 1400px) {
  .MuiContainer-maxWidthLg {
    max-width: 100%;
  }
}

@media (max-width: 959px ) {
  .makeStyles-appBarShift-4 {
    margin-left: 0;
    width: 100%;
  }
}

@media (max-width: 599px) {
  .mainlogo {
    display: none;
  }
}
