.MuiFormLabel-root {
  /* color: rgba(255, 255, 255, 0.35); */
}



.MuiInput-underline:before {
  /* border-bottom: 1px solid white; */
}

.MuiFormLabel-root.Mui-focused {
  /* color: white; */
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  /* border-bottom: 2px solid white; */
}

.MuiInput-underline:after {
  /* border-bottom: 2px solid white; */
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  /* border-color: white; */
}

.MuiOutlinedInput-notchedOutline {
  /* border-color: rgba(255, 255, 255, 0.35); */
}

.MuiInputLabel-outlined {
  transform: translate(14px, 12px) scale(1);
}

.error .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}

.error.MuiFormControl-root {
  width: 100%;
}

.error .MuiFormLabel-root {
  color: red;
}

.fields .MuiOutlinedInput-notchedOutline {
  border-radius: 0;
}

.MuiOutlinedInput-input {
  padding: 10px 14px;
}

.PrivateSwitchBase-root-208,
.PrivateSwitchBase-root-244 {
  padding: 0;
  margin-right: 0.5rem;
}

.wrapper {
  padding: 0 1rem;
  padding-top: 3%;
}

.loginInfoSection {
  display: flex;
  color: white;
}

.fields {
  width: 100%;
}

.information {
  width: 40%;
  display: flex;
  padding: 4rem 0;
}

.information div:first-child {
  width: 20%;
}

.information div:first-child img {
  width: 100%;
}

.information div:last-child {
  width: 80%;
  padding: 1.5rem 2rem;
}

.information h6 {
  margin-bottom: 1.25rem;
}

.loginSignupSection {
  width: 100%;
  margin: 0 3rem;
  display: flex;
  justify-content: center;
  /* background-image: linear-gradient(135deg, #3b3b3b, #3b3b3b, #360000); */

  .register {
    color: var(--momo-orange-color);
    cursor: pointer;
    &:hover {
      font-weight: 600;
    }
  }
}

.form-wrapper {
  width: 50%;
  text-align: center;
  padding: 4rem 3rem;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.form-wrapper span {
  margin-right: 0;
}

.signUp {
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 3rem;
}

.ticked {
  background: grey;
}

.ticked:hover {
  color: grey;
}

.loginLogo {
  width: 15%;
}

.loginLogo img {
  width: 100%;
}

.errorMessage {
  color: red;
  /* text-align: left; */
  display: block;
}

.loginOptions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.forgot-password {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.or {
  margin: 1.25rem 0;
}

.otherLogins img {
  width: 7%;
  margin: 0 0.75rem;
}

.loginBtn {
  min-width: 115px;
  border-radius: 0;
}

.askSignUp {
  margin-bottom: 1.25rem;
}

.signUpMessage {
  margin-bottom: 2.25rem;
}

.learnMore {
  font-size: 1.25rem;
  padding: 0.75rem 2.25rem;
  border-radius: 0;
  margin-top: 4rem;
}

.accept-terms {
  margin: 0.25rem 0;
  text-align: left;
}

.loginInputFields {
  display: flex;
  flex-direction: column;
}

.modal {
  position: absolute;
  width: 100%;
  height: 200%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 5;

  .loginModal {
    width: 35%;
    text-align: center;
    position: fixed;
    left: 35%;
    top: 25%;
    padding: 3.5rem 2rem 2rem;
    background-color: #303030;
    @media (max-width: 599px) {
      width: 100%;
      left: 0;
      top: 20%;
      padding: 3rem 3rem;
    }

    span {
      margin-right: 0;
    }
    .closeBtn {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

.modal.hide {
  display: none;
}

.modal.show {
  display: show;
  // flex-direction: column;
  // justify-content: center;
}

.qrloginContainer {
    padding: 1rem;
    color: white;
    text-align: center;

    .message {
      width: 50%;
      margin: auto;
    }
}

.MuiOutlinedInput-notchedOutline { border-color: rgba(0,0,0,0.3); }
.MuiFormLabel-root { color: rgba(0,0,0,0.65); }

.phoneLoginWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 5;
  padding-left: 16rem;

  .phoneLoginContainer {
    width: 60%;
    margin: auto;
    padding: 2rem;
    background-color: white;
    color: black;
  }
  button {
    margin: 0 1rem;
  }
}

@media (max-width: 1365px) {
  .wrapper {
    padding-top: 2%;
    padding: 0;
  }

  .information div:last-child {
    padding: 0.5rem 1rem;
  }

  .learnMore {
    font-size: 1rem;
    padding: 0.5rem 2.25rem;
    margin-top: 2rem;
  }

  .loginSignupSection {
    margin: 0;
  }

  .form-wrapper {
    padding: 4rem 2rem;
  }

  .signUp {
    padding: 0 1rem;
  }
}

@media (max-width: 1279px) {
  .loginLogo {
    width: 25%;
    margin: auto;
  }

  .loginInfoSection {
    flex-direction: column;
    margin-top: 1.25rem;
  }

  .information,
  .loginSignupSection {
    width: 100%;
  }

  .information {
    order: 2;
  }

  .loginSignupSection {
    order: 1;
  }

  .learnMore {
    margin-top: 4rem;
  }
}

@media (max-width: 959px) {
  .loginSignupSection {
    display: initial;
  }

  .form-wrapper {
    width: 100%;
  }

  .signUp {
    padding: 4rem 1rem;
    width: 100%;
  }
}

@media (max-width: 599px) {
  .loginLogo {
    width: 30%;
  }

  .makeStyles-content-10 {
    padding: 24px 0px;
  }

  .information {
    padding: 4rem 1rem;
  }
}
