@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke!important;
  color: black!important;
  /* --primary-color-black: #3b3b3b;
  --secondary-color-black: #111111;
  background-color: var(--secondary-color-black); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  width: 100%;
}

:root {
  --momo-orange-color: #e93e22;
}

/* .MuiAppBar-colorPrimary,
.MuiDrawer-paperAnchorDockedLeft {
  background-color: var(--primary-color-black);
  box-shadow: none;
} */

/* .MuiListItemIcon-root,
.MuiIconButton-root,
.MuiPaper-root {
  color: rgba(255, 255, 255, 0.8);
}

i {
  color: rgba(255, 255, 255, 0.8);
}

.MuiDivider-root {
  background-color: rgba(255, 255, 255, 0.12);
}

.account .MuiListItemIcon-root,
.account .MuiIconButton-root,
.account .MuiPaper-root {
  color: black;
}



.botMore span,
.botMore i {
  color: black;
} */
.toast > div {
  background: rgb(26, 26, 26) !important;
}
.toast > div > button {
  color: white !important;
}
.toast-body {
  text-transform: uppercase;
  padding-left: 1rem;
}
.toast-progress {
  background: linear-gradient(to right, #5ac8fa, #5856d6) !important;
}
.MuiTypography-root{
  color: #9fa1a3
}
.textChange{
  color: #9fa1a3
}
.headerDecoration{
 text-decoration-color: #e93e22;
}

 .btn-secondary:hover   {
  background-color: #e93e22 !important;
}

/* .btn-secondary.active {
  background-color: #0b51c5 !important; } */
  /* .button:active {
    background-color: green;
  } */

.closeBtn { background-color: darkred; color: white; }
.momoOrangeBtn { background-color: var(--momo-orange-color); color: white; }

.button a:active, .button a:focus, .button a:hover {
    background-color: whitesmoke;
  }
  
.offline{
    transform: translate(30%, 100%);
  }

/* .online{
  background-color: #0a1117;
} */
  
.fillContent{
    background: #e93e22;
    color: white;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    height: auto;
    line-height: normal;
    padding: 5px 5px 5px 5px;
    position: absolute;
    right: 16px;
    top: 0px;
    width: auto;
    z-index: 4;

}
.fillContent1{
  background: #e93e22;
    color: white;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    height: auto;
    line-height: normal;
    padding: 5px 5px 5px 5px;
    position: absolute;
    right: 16px;
    top: 0px;
    width: auto;
    z-index: 4;
 

}

@media (min-width: 700px){
.makeStyles-search-83 {
    width: 100% !important;
}
}

@media (min-width: 700px){
.jss83 {
    width: 100% !important;
}
}

.jss83 {
  border: 1.35px solid rgba(0,0,0,0.5);
  background-color: rgba(0,0,0,0.05);
}
.makeStyles-search-83 {
  border: 1.35px solid rgba(0,0,0,0.5);
  background-color: rgba(0,0,0,0.05);
}

.makeStyles-inputRoot-85 {
  color: inherit;
  width: 100% !important;
}

@media(max-width: 960px){
.jss4 {
  width: 100% !important;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  margin-left: 240px;
}
}

.slick-slide{
  padding-right: 15px;
}

.modal-backdrop.show {
  opacity: 0 !important;
}

.modal-content{
  transform: translate(15px, 30px);
  margin-right: 35px !important;
}

.modal-backdrop{
  z-index:-1 !important;
  
}
.modal{
  background-color: whitesmoke !important;
}



.btn-secondary {
  color: black !important;
  background-color: rgba(0,0,0,0.2) !important ;
  border-color: #6c757d;
}

.modal.fade .modal-dialog {
  transform: translateY(-340px) !important;
}

@media(min-width: 700px){
.search-and-bar{
  padding: 0% 19%;
}
}

/* .slick-slide {
  width: 100%;
/* } */
/* @media(min-width:700px){
.custom{
  width: 1200px;
} 
} */
/* @media(min-width:1400px){
  .custom{
    width: 1500px;
  } 
  } */

  .custom{
    width: 1390px;
  }

.slick-prev{
  left: -20px !important;
  background: darkgray;
}

.slick-next{
  background: darkgray;
  right: -20px !important
}

.imageDiv1 { overflow: hidden; position: relative; }
.imageDiv1 .playlistInThumbnail {
  position: absolute;
  top: 0;
  right: 0;
  width: 35%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.imageDiv1 .playlistInThumbnail h6 {
  color: white;
  text-align: center;
}

.imageDiv1 .playlistInThumbnail img {
  transform: translate(25px, 0px);
}

.imageDiv{
  height: 300px !important;
}

.slick-slide .slick-active .slick-cloned{
  width: 0px !important;
}

.makeStyles-content-11{
  overflow-y: hidden;
}

.homepageGridContainer {
  padding: 2.3rem !important;
}

@media(min-width : 601px){
.button-and-centered{
  padding: 0% 27% 20% 0%;
}
}

@media(max-width : 600px){
.button-and-centered{
  padding: 0% 0% 20% 0%;
}
}

@media(max-width : 400px){
.image-and-resize{
  height: 150px !important;
}
}

@media(min-width : 1500px) and (max-width : 1600px){
  .image-and-resize{
    height: 250px !important;
  }
  }
  @media(min-width : 1601px) and (max-width :2000px ){
    .image-and-resize{
      height: 300px !important;
    }
    }
@media(min-width : 400px) and (max-width : 1400px){
    .image-and-resize{
      height: 200px !important;
    }
    }

  @media(min-width : 2001px){
    .image-and-resize{
      height: 420px !important;
    }
    }

  @media(max-width : 450px){
    .resize-musicvideo{
      height: 100px !important;
    }
  }
  @media (min-width:450px) and (max-width:600px){
    .resize-musicvideo{
      height: 140px !important;
    }
  }
  @media(max-width : 400px){
    .modalqr{
      transform: translate(-29px, 0px);
    }
  }

  @media (min-width:400px) and (max-width:460px){
    .modalqr{
      transform: translate(-25px, 0px);
    }
  }

  @media (min-width:461px){
    .modalqr{
      transform: translate(0px, 0px);
    }
  }

  .jss11 {
    overflow-y: hidden;
}

.underline hr {
  margin: 0rem 0 !important;
}

/* .MuiTypography-root {
  color: rgb(233, 62, 34) !important ;
} */
