.main-container {
  /* padding: 1rem 2rem; */
}

.video-container {
  width: 100%;
  padding: 8px 0 !important;
}

h5,
h6,
p {
  /* color: rgba(255, 255, 255, 0.8); */
}

.video-title h6 {
  /* padding: 12px 10px;
  padding-bottom: 0; */
  padding-left: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  margin-top: 0.5rem;
}

.video-title a {
  color: inherit;
}

hr {
  margin: 2rem 0;
}

.video-title {
  display: flex;
  justify-content: space-between;
  padding-right: 0 !important;
}
.video-container {
  margin: 0 auto;
  max-width: 1500px;
}
.video-grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-row-gap: 3rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  padding: 1.75rem 0 4rem 0;
}
.video-grid-container img {
  width: 100%;
}
.thumbnail {
  position: relative;
}
.over-thumbnail {
  position: absolute;
  bottom: 5%;
  background: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 0 0.5rem;
}
.over-thumbnail.duration {
  left: 3%;
}
.over-thumbnail.view {
  right: 5%;
  display: flex;
  align-items: center;
}
.over-thumbnail.view i {
  font-size: 1rem;
  padding-right: 0.25rem;
}
.video-grid-container a {
  text-decoration: none;
}
.video-grid-container div {
  /* background: grey; */
  padding-right: 8px;
}

.video-title {
  display: flex;
  justify-content: space-between;
}
.video-title.publisher {
  padding: 0 0.5rem;
  padding-top: 0rem;
}

span {
  /* color: rgba(255, 255, 255, 0.6); */
}
.imageDiv { height: 161.55px; overflow: hidden; position: relative; }
.imageDiv .playlistInThumbnail {
  position: absolute;
  top: 0;
  right: 0;
  width: 35%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.imageDiv .playlistInThumbnail h6 {
  color: white;
  text-align: center;
}
.imageDiv .playlistInThumbnail img {
  transform: translate(47px, 0px);
}
/* .homepageGridItem {
  padding: 10px;
  margin: 10px;
  background: white;
  box-shadow: 0 0 5px -4px rgba(0,0,0,0.65);
  border-radius: 3px;
} */
.homepageGridItem h6 { 
  color: black; 
  font-weight: 600; 
  margin-bottom: -6px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; 
}
.homepageGridItem .cardContent { padding: 0.25rem 0.5rem; }
.navButtons { padding: 0 2rem; }
.navButtons button {
    padding: 0.15rem 1rem;
    background-color: #EA3E22;
    color: white;
}
.likes {
  display: flex;
}

.likes button {
  padding: 0;
  margin-right: 5px;
}

.PrivateSwitchBase-root-225 {
  padding: 0;
}

.oneVideoListingWrapper {
  display: flex;
  margin-bottom: 1.5rem;
}
.oneVideoListingWrapper a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.oneVideoListingWrapper img {
  width: 100%;
  cursor: pointer;
}
.oneVideoListingWrapper div:first-child {
  width: 35%;
  position: relative;
}

.oneVideoListingWrapper div:nth-child(2) {
  width: 70%;
  padding-left: 10px;
  word-break: break-all;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.oneVideoListingWrapper div:last-child button {
  /* width: 5%; */

  padding: 0;
}
.upNext {
  margin-bottom: 1.75rem;
}

/* NEW CSS */
/* .row {
  width: 100%;
  margin: 0;
} */
.homepageGridContainer {
  padding: 2rem;
}
.homepageGridContainer span { 
  color: var(--momo-orange-color);
}
.homepageGridContainer .videoInfo1, .geetInfo1 {
  background-color: white;
  box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.75);
}
.geetInfo1 { margin-bottom: 1.25rem; }
.geetInfo1 .cardContent { padding: 0 5px; text-align: center; }

@media (max-width: 1366px) {
  .video-container {
    margin: 0 auto;
    max-width: 1024px;
  }
  .video-grid-container {
    grid-template-columns: 25% 25% 25% 25%;
  }
}

@media (max-width: 1279px) {
  .video-container {
    margin: 0 auto;
    max-width: 960px;
  }
  .video-grid-container {
    grid-template-columns: 33% 33% 33%;
  }
}

@media (max-width: 959px) {
  .video-container {
    margin: 0 auto;
    max-width: 820px;
  }
  .video-grid-container {
    grid-template-columns: 50% 50%;
  }
  .channelTitle {
    border-right: 0px !important;
  }

  .seeMore {
    padding: 0;
    border-bottom: 1px solid dimgrey;
  }
  .geetContainer {
    padding: 0 1rem;
  }
}

@media (max-width: 599px) {
  .react-jw-player-container {
    margin: -16px 0;
    max-width: 100vw;
  }

  .video-container,
  .channelDescription {
    /* margin: 0 auto; */
    max-width: 100vw;
  }
  .video-grid-container {
    grid-template-columns: auto;
  }

  .video-grid-container div {
    padding-right: 0;
  }
}

@media (max-width: 960px) {
  .main-container {
    margin-bottom: 40px;
  }
}
